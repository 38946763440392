<template>
  <b-field :label="this.block.label" horizontal>
        <b-input v-model="value"></b-input>
    </b-field>
</template>
  
  
  <script>
  export default {
    components: {},
    props: {
      block:{
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        value: "",
      };
    },
    computed: {},
    methods: {
      TouchStart() {
        this.touchDown = true;
        console.log("touch start")
      },
      TouchEnd(e) {
        e.stopPropagation();
        this.touchDown = false;
        console.log("touch end")
      },
    },
    watch:{
      value: function () {
        this.$socket.client.emit('gameMessage', {
          type: 'controllerData',
          controllerType: 'inputField',
          name: this.block.blockName,
          input: {
            value: this.value,
          }
        })
      }
    },
    mounted() {
      //
    },
  };
  </script>
  
  <style scoped lang="scss">
  </style>
  